<template>
  <span class="regular-12 text-black text-start mx-2">
    <button class="btn btn-secondary btn-sm" v-on:click="minus">
      <BaseIcon name="minus-circle" />
    </button>
    <EditOnClick v-model="value" v-on:update="setValue" />
    <button class="btn btn-secondary btn-sm" v-on:click="plus">
      <BaseIcon name="plus-circle" />
    </button>
  </span>
</template>

<script>
import { defineAsyncComponent } from "vue";
const BaseIcon = defineAsyncComponent(() => import("../../icons/BaseIcon.vue"));
import { useStore } from "vuex";
import EditOnClick from "../../inputs/EditOnClick";
export default {
  name: "CreateIncome",
  components: { EditOnClick, BaseIcon },
  props: {
    id: Number,
    type: String,
    extraRow: [Object, Array, Number, String],
    row: Object,
    modelValue: String,
  },
  data() {
    return {
      store: useStore(),
      show: false,
      value: this.modelValue ?? 0,
    };
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
      this.$emit("change", this.value);
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
  emits: ["update:modelValue", "change"],
  methods: {
    plus() {
      this.value++;
      window.apps.callEvent(
        "changeValue:manualIncoming",
        this.extraRow ?? this.id,
        1
      );
    },
    minus() {
      if (this.value > 0) {
        this.value--;
      }
      window.apps.callEvent(
        "changeValue:manualIncoming",
        this.extraRow ?? this.id,
        -1
      );
    },
    setValue() {
      this.value = Number.parseInt(this.value);
      if (this.value < 0) {
        this.value = 0;
      }
      window.apps.callEvent(
        "setValue:manualIncoming",
        this.extraRow ?? this.id,
        this.value
      );
    },
    showModal() {
      window.apps.callEvent(
        "create:manualIncoming",
        this.extraRow ?? this.id,
        this.value
      );
    },
  },
};
</script>
